import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  AutocompleteArrayInput,
} from "react-admin";
import { featureFlags, staffStati, stati, userStati } from "../../data/common";
import timezones from "../../data/timezones";

const UserEdit = (props) => {
  return (
    <Edit
      title="Edit User"
      {...props}
      mutationMode="pessimistic"
      redirect={false}
    >
      <SimpleForm>
        <TextInput fullWidth disabled source="id" />
        <TextInput
          fullWidth
          source="email"
          helperText="Use *EXTREME* caution when changing a user's email: Make SURE they have emailed you from the NEW email address, and confirm their request is not suspicious. We'll attach a note about the change to staff_notes. The user will be logged out and will need to login again with their new email address."
        />
        <TextInput
          source="challenge_id"
          helperText="Challenge ID: To restart, enter challenge1, infinite1, mixed1, mixed2, or sample-challenge"
          fullWidth
        />
        {/* <TextInput source="chat_link" />
        <TextInput source="chat_username" /> */}

        <AutocompleteArrayInput source="feature_flags" choices={featureFlags} />

        <TextInput
          source="group"
          helperText="Set to a group's ID from the group page"
        />
        <TextInput
          source="division"
          helperText="For analytics on the leadership dashboard"
        />
        <TextInput
          source="department"
          helperText="For analytics on the leadership dashboard"
        />
        <NumberInput
          source="log_lesson_day"
          helperText="*Caution*: Changing this field will reset your daily lesson progress to the given day. Only ever change this if you know exactly what you are doing"
        />
        <TextInput
          source="magic_link_code"
          helperText="You can manually set this to 6 digits to aid a customer or login as them"
        />
        <TextInput source="nickname" />
        <TextInput source="profile_image_link" />

        {/* <TextInput
          disabled
          source="status"
          helperText="For now status shouldn't be changed manually. Let Warren know if there
          is a need to change"
        /> */}
        <SelectInput source="status" choices={userStati} />
        <SelectInput source="staff_status" choices={staffStati} />
        <TextInput source="staff_notes" multiline fullWidth />

        <TextInput
          disabled
          source="timezone"
          label="Timezone Currently"
          helperText="Please use the drop down below to set this to a valid timezone"
        />
        <SelectInput
          source="timezone"
          choices={timezones.map((e) => {
            return { id: e, name: e };
          })}
        />
        <NumberInput
          source="token_version"
          helperText="Increase this to log the user out of all devices/sessions"
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
